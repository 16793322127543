<template>
  <div>
    <b-breadcrumb
      :items="[
        {
          text: 'DAFTAR USER',
          href: '#/users',
        },
        {
          text: 'DETAIL USER',
          active: true,
        },
      ]"
    ></b-breadcrumb>
    <b-card>
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col md="12" class="text-right">
              <b-button
                :href="'#/users/' + $route.params.id + '/edit'"
                size="md"
                variant="success"
                ><font-awesome-icon icon="pencil-alt" /> Edit</b-button
              >
            </b-col>
          </b-row>
        </b-col>
        <b-col md="8">
          <b-row class="mt-3">
            <b-col md="3">
              <strong>User ID</strong>
            </b-col>
            <b-col md="9">
              {{ model.username }}
            </b-col>
          </b-row>
        </b-col>
        <b-col md="8">
          <b-row class="mt-3">
            <b-col md="3">
              <strong>NIK</strong>
            </b-col>
            <b-col md="9">
              {{ model.nik }}
            </b-col>
          </b-row>
        </b-col>
        <b-col md="8">
          <b-row class="mt-3">
            <b-col md="3">
              <strong>Nama</strong>
            </b-col>
            <b-col md="9" style="text-transform: capitalize;">
              {{ model.name }}
            </b-col>
          </b-row>
        </b-col>
        <b-col md="8">
          <b-row class="mt-3">
            <b-col md="3">
              <strong>Email</strong>
            </b-col>
            <b-col md="9">
              {{ model.email }}
            </b-col>
          </b-row>
        </b-col>
        <b-col md="8">
          <b-row class="mt-3">
            <b-col md="3">
              <strong>Kata Sandi</strong>
            </b-col>
            <b-col md="9">
              {{ model.kata_sandi_asli }}
            </b-col>
          </b-row>
        </b-col>
        <b-col md="8">
          <b-row class="mt-3">
            <b-col md="3">
              <strong>No WA</strong>
            </b-col>
            <b-col md="9">
              {{ model.whatsapp_number !== null ? model.whatsapp_number : "-" }}
            </b-col>
          </b-row>
        </b-col>
        <b-col md="8">
          <b-row class="mt-3">
            <b-col md="3">
              <strong>Kota/Kabupaten</strong>
            </b-col>
            <b-col md="9">
              {{ model.regency }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: {
        user: true,
      },
      apiUrl: {
        user: process.env.VUE_APP_API_URL + "users",
      },
      model: {
        id: null,
        nik: null,
        name: null,
        username: null,
        kata_sandi_asli: null,
        whatsapp_number: null,
        email: null,
        regency: null,
      },
    };
  },
  watch: {
    "$route.params.id": function (val) {
      if (val !== undefined) {
        this.getDetail();
      }
    },
  },
  mounted() {
    if (this.$route.params.id !== undefined) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.isLoading.user = true;
      this.$http
        .get(this.apiUrl.user + "/" + this.$route.params.id)
        .then((response) => {
          this.model.id = response.data.id;
          this.model.nik = response.data.nik;
          this.model.name = response.data.name;
          this.model.username = response.data.username;
          this.model.kata_sandi_asli = response.data.kata_sandi_asli;
          this.model.email = response.data.email;
          this.model.regency = response.data.regency.name;
          this.model.whatsapp_number = response.data.whatsapp_number;
          this.isLoading.user = false;
        })
        .catch((error) => {
          this.isLoading.user = false;
          this.$store.dispatch("notification/error", error);
          if (
            typeof error !== "undefined" &&
            typeof error.response !== "undefined" &&
            typeof error.response.status !== "undefined" &&
            error.response.status === 404
          ) {
            this.$router.push("/notfound");
          }
        });
    },
  },
};
</script>