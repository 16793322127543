var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-breadcrumb',{attrs:{"items":[
      {
        text: 'DAFTAR USER',
        href: '#/users',
      },
      {
        text: 'DETAIL USER',
        active: true,
      } ]}}),_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"md":"12"}},[_c('b-button',{attrs:{"href":'#/users/' + _vm.$route.params.id + '/edit',"size":"md","variant":"success"}},[_c('font-awesome-icon',{attrs:{"icon":"pencil-alt"}}),_vm._v(" Edit")],1)],1)],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"3"}},[_c('strong',[_vm._v("User ID")])]),_c('b-col',{attrs:{"md":"9"}},[_vm._v(" "+_vm._s(_vm.model.username)+" ")])],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"3"}},[_c('strong',[_vm._v("NIK")])]),_c('b-col',{attrs:{"md":"9"}},[_vm._v(" "+_vm._s(_vm.model.nik)+" ")])],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"3"}},[_c('strong',[_vm._v("Nama")])]),_c('b-col',{staticStyle:{"text-transform":"capitalize"},attrs:{"md":"9"}},[_vm._v(" "+_vm._s(_vm.model.name)+" ")])],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"3"}},[_c('strong',[_vm._v("Email")])]),_c('b-col',{attrs:{"md":"9"}},[_vm._v(" "+_vm._s(_vm.model.email)+" ")])],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"3"}},[_c('strong',[_vm._v("Kata Sandi")])]),_c('b-col',{attrs:{"md":"9"}},[_vm._v(" "+_vm._s(_vm.model.kata_sandi_asli)+" ")])],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"3"}},[_c('strong',[_vm._v("No WA")])]),_c('b-col',{attrs:{"md":"9"}},[_vm._v(" "+_vm._s(_vm.model.whatsapp_number !== null ? _vm.model.whatsapp_number : "-")+" ")])],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"3"}},[_c('strong',[_vm._v("Kota/Kabupaten")])]),_c('b-col',{attrs:{"md":"9"}},[_vm._v(" "+_vm._s(_vm.model.regency)+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }