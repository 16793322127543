<template>
  <div>
    <b-breadcrumb
      v-if="model.id !== null"
      :items="[
        {
          text: 'Daftar Admin',
          href: '#/users',
        },
        {
          text: 'Detail Admin',
          href: '#/users/' + model.id,
        },
        {
          text: 'Ubah Admin',
          active: true,
        },
      ]"
    ></b-breadcrumb>
    <b-breadcrumb
      v-else
      :items="[
        {
          text: 'Daftar Admin',
          href: '/#/users',
        },
        {
          text: 'Tambah Admin',
          active: true,
        },
      ]"
    ></b-breadcrumb>
    <b-card
      style="max-width: 500px; margin: auto"
    >
      <b-form @submit.prevent="onSubmit">
        <b-form-group class="capitalize" id="input-group-1" label-for="input-1">
          <label for="">Nama penanggung jawab <sup class="text-danger">*</sup></label>
          <b-form-input
            id="input-1"
            v-model="model.name"
            placeholder="Ketikkan Nama Penanggung Jawab"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group class="capitalize"
          id="input-group-2"
          label-for="input-2"
        >
          <label for="">NIK penanggung jawab</label>
          <b-form-input
            id="input-2"
            type="text"
            v-model="model.nik"
            autocompleted="false"
            placeholder="Ketikkan NIK"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="capitalize"
          id="input-group-1"
          label-for="input-1"
        >
          <label for="">Kota/Kabupaten <sup class="text-danger">*</sup></label>
          <treeselect
            v-model="model.regency_id"
            :multiple="false"
            placeholder="Pilih Kota/Kabupaten"
            :options="options.regencies"
          />
        </b-form-group>
        <b-form-group class="capitalize"
          id="input-group-3"
          label-for="input-3"
          v-if="model.id === null"
        >
          <label for="">Nama Akun <sup class="text-danger">*</sup></label>
          <b-form-input
            id="input-3"
            v-model="model.username"
            placeholder="Ketikkan Nama Akun"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group class="capitalize" id="input-group-2" label-for="input-2" 
          v-if="model.id === null">
          <label for="">Email</label>
          <b-form-input
            id="input-2"
            type="email"
            v-model="model.email"
            placeholder="Ketikkan Email"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="capitalize" id="input-group-4" label="" label-for="input-4">
          <label for="">Nomor Kontak <sup class="text-danger">*</sup></label>
          <b-form-input
            id="input-4"
            v-model="model.whatsapp_number"
            type="text"
            placeholder="Ketikkan Nomor Kontak"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="capitalize"
          id="input-group-4"
          label-for="input-4"
        >
          <label for="">Kata Sandi <sup class="text-danger">*</sup></label>
          <b-input-group>
            <b-form-input class="capitalize"
              :type="modelType.password"
              id="input-4"
              v-model="model.password"
              autocomplete="off"
              placeholder="Ketikkan Kata Sandi"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                variant="secondary"
                v-if="modelType.password === 'password'"
                @click.prevent="modelType.password = 'text'"
                ><font-awesome-icon icon="eye"
              /></b-button>
              <b-button
                variant="secondary"
                v-else
                @click.prevent="modelType.password = 'password'"
                ><font-awesome-icon icon="eye-slash"
              /></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-button type="submit" variant="primary" :disabled="isLoading"
          ><font-awesome-icon v-if="isLoading" icon="spinner" spin />
          Simpan</b-button
        >
      </b-form>
    </b-card>
  </div>
</template>
<script>
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect },
  data() {
    return {
      isLoading: false,
      apiUrl: {
        user: process.env.VUE_APP_API_URL + "users",
        regency: process.env.VUE_APP_API_URL + "regencies",
      },
      modelType: {
        password: "password",
      },
      options: {
        regencies: [],
      },
      model: {
        id: null,
        nik: null,
        role: "Admin",
        name: null,
        regency_id: null,
        username: null,
        whatsapp_number: null,
        email: null,
        generate_password: 1,
      },
    };
  },
  watch: {
    "$route.params.id": function (val) {
      if (val !== undefined) {
        this.getDetail();
      } else {
        this.resetForm();
      }
    },
  },
  mounted() {
    if (this.$route.params.id !== undefined) {
      this.getDetail();
    }
    this.getRegency();
  },
  methods: {
    resetForm() {
      this.model.id = null;
      this.model.name = null;
      this.model.username = null;
      this.model.email = null;
      this.model.whatsapp_number = null;
      this.model.generate_password = 0;
    },
    onSubmit() {
      this.isLoading = true;
      if (this.model.id == null || this.model.id == "") {
        this.$http.post( this.apiUrl.user, this.model )
        .then((response) => {
          this.isLoading = false;
          this.$store.dispatch(
            "notification/success",
            "Admin berhasil diubah."
          );
          this.$router.push("/users/" + response.data.id);
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
        });
      } else {
        this.$http.patch( this.apiUrl.user + '/' + this.model.id, this.model )
        .then((response) => {
          this.$store.dispatch(
            "notification/success",
            "Admin berhasil disimpan."
          );
          this.$router.push("/users/" + response.data.id);
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
        });
      }
    },
    getRegency() {
      this.isLoading = true;
      this.$http
        .get(
          this.apiUrl.regency +
            "?limit=1000&province_id=950b9d3a-c996-4207-97e7-5a2858981979"
        )
        .then((response) => {
          this.options.regencies = [];
          response.data.data.forEach((data) => {
            this.options.regencies.push({
              id: data.id,
              label: data.name,
            });
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
          if (
            typeof error !== "undefined" &&
            typeof error.response !== "undefined" &&
            typeof error.response.status !== "undefined" &&
            error.response.status === 404
          ) {
            this.$router.push("/notfound");
          }
        });
    },
    getDetail() {
      this.isLoading = true;
      this.$http
        .get(this.apiUrl.user + "/" + this.$route.params.id)
        .then((response) => {
          this.model.id = response.data.id;
          this.model.name = response.data.name;
          this.model.nik = response.data.nik;
          this.model.regency_id = response.data.regency_id;
          this.model.username = response.data.username;
          this.model.email = response.data.email;
          this.model.whatsapp_number = response.data.whatsapp_number;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
          if (
            typeof error !== "undefined" &&
            typeof error.response !== "undefined" &&
            typeof error.response.status !== "undefined" &&
            error.response.status === 404
          ) {
            this.$router.push("/notfound");
          }
        });
    },
  },
};
</script>